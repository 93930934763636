$(document).on('turbolinks:load', function () {
  const modal = $('#dash-form-edit')
  bind_modal_hide(modal)
  bind_modal_show(modal)
  bind_project_dropdown()
})

function bind_project_dropdown() {
  $('#other_project_dropdown').change(function () {
    const id = this.value
    const name = this.selectedOptions[0].text
    add_project_to_table(id, name)
    remove_option_from_dropdown(id)
  })
}

function bind_modal_hide(modal) {
  modal.on("hide.bs.modal", function (e) {
    $('.modal-content').html("")
  })
}

function bind_modal_show(modal) {
  function error_message(event) {
    var msg = "Sorry but there was an error: "
    return `
      <p class='alert alert-danger'>i
        ${msg}${event.status}: ${event.statusText}
      </p>
    `
  }
  modal.on("show.bs.modal", function (e) {
    $('.modal-content').load(e.relatedTarget.dataset.url, (_formdata, formstatus, formxhr) => {
      if ( formstatus == "error" ) {
        $( "#error" ).html(error_message(formxhr))
        setTimeout(function () { modal.modal('hide') }, 500)
      } else {
        const form = document.querySelector("#time_reg_form")
        form.addEventListener("ajax:success", (event) => {
          window.location.reload() //good enough for now. lazy but OK vs process ajax response and update
        })
        form.addEventListener("ajax:error", (event) => {
          const [data, status, xhr] = event.detail
          if (xhr.status == 422) {
            $('.modal-content').html(data)
          } else {
            $( "#modal-error" ).html(error_message(xhr))
          }
        })
        $(form).find('[autofocus]').select()
      }
    })
  })
}

function add_project_to_table(id, name) {
    const trs = $("#timeregistration_overview").find("tr")
    const new_tr = $('<tr></tr>')
    $('.dash tr:first th').each(function (index, value) {
      if (index == 0) {
        new_tr.append('<td>' + name + '</td>')
      } else {
        new_tr.append(`
          <td>
            <a class='btn edit clickable' data-toggle='modal' data-target='#dash-form-edit' href='#'
               data-url='/timeregistrations/modal_new?date=${value.dataset.fullDate}&project_id=${id}'>
              <i className='glyphicon glyphicon-edit glyphicon-white'></i>
              &nbsp;
            </a>
          </td>`
        )
      }
    })
   $(trs[trs.length-4]).after(new_tr)
}

function remove_option_from_dropdown(id) {
  $(`#other_project_dropdown option[value='${id}']`).remove()
}
